<template>
  <!-- 招聘人员 -->
  <div class="staff" :class="{'classBorder':isBorder}">
    <div class="staff_info flex">
      <div @click="openDialogVisible" class="positionDetails_btn flex_aling_justify_center">
        立即沟通 <i class="el-icon-arrow-right" style=" color: #f43a47;"></i>
      </div>
      <!-- v-if="item.video.length>0" -->
      <template>
        <div class="staff_video" @click.capture="play" v-on:mouseenter="MouseOver" v-on:mouseout="MouseOut">
          <video class="staff_img" ref="video" :controls="false" :poster="item.video[0].video_photo">
            <source :src="item.video[0].video_url[0]" type="video/mp4">
          </video>
          <!-- 遮罩层 -->
          <!-- <div class="mask" v-if="isMask">
            <i class=" el-icon-video-play"></i>
          </div> -->
        </div>
      </template>
      <div class="staff_info_right" @click="jumpRY(item)">
        <div class="staff_name flex_aling_center">
          <span> {{item.real_name}}</span>
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            视觉年龄：
          </div>
          {{item.note.begin_age}}- {{item.note.end_age}}岁
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            性别:
          </div>
          {{item.note.sex==1?'女':'男'}}
        </div>
        <!-- <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            可接受角色类型:
          </div>
          <div class="staff_text_values">
            <span v-for="(value) in item.can_person_attr">{{ value }}</span>
          </div>
        </div> -->
        <div class="">
          <div class="staff_text    flex_aling_center">
            <div class="staff_text_label staff_text_label1">
              擅长角色类型：
            </div>
            <template v-if="item.note.can_person_attr.length>0">
              <div class="ellipsis-container">
                <span style="margin-right:3px" v-for="(value) in item.note.can_person_attr">{{ value }}</span>
              </div>
            </template>
          </div>
        </div>

      </div>
      <div class="dataImagesList">
        <div class="dataImagesList_item" v-if="item.all_choose_url[0]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[0]" :preview-src-list="[item.all_choose_url[0]]">
          </el-image>
        </div>
        <div class="dataImagesList_item" v-if="item.all_choose_url[1]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[1]" :preview-src-list="[item.all_choose_url[1]]">
          </el-image>
        </div>
        <div class="dataImagesList_item" v-if="item.all_choose_url[2]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[2]" :preview-src-list="[item.all_choose_url[2]]">
          </el-image>

        </div>
        <div class="dataImagesList_item" v-if="item.all_choose_url[3]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[3]" :preview-src-list="[item.all_choose_url[3]]">
          </el-image>
        </div>
      </div>
    </div>
    <div class="staff_c  tow_ellipsis">
      <img src="@/assets/image/image/yuangong.png" alt="">
      <span>代表作：</span>
      <span class="work_name" v-for="(value,i) in item.work" :key="i">《{{value.work_name}}》</span>
    </div>
  </div>
</template>

<script >
import {
  job_auth,
  coll_job,
  connect_member,
  job_list,
  block,
} from "@/utils/network.js";
import { mapState } from "vuex";
export default {
  props: {
    isBorder: {
      type: Boolean,
      require: false,
      default: false,
    },
    item: {
      type: Object,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      isMask: false,
    };
  },
  created() {},
  computed: {
    ...mapState(["userInfo", "company"]),
  },
  methods: {
    async openDialogVisible() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }

      this.gochat();
      // this.dialogVisible = true;
    },
    async gochat(crawItem) {
      // 选择职位
      // 1企业邀请试戏 2 用户申请试戏
      let chat = {
        company_member_id: this.company.member.member_id,
        member_id: this.item.member_id, // 对方的id
        faqi_type: 1,
        job_id: "", // 暂时没有crawItem.id
        company_id: this.company.company.id,
      };
      // console.log(crawItem, "crawItem");
      connect_member(chat);
      localStorage.setItem("chatInfo", JSON.stringify(chat));

      let data = {
        company_member_id: this.company.member.member_id,
        member_id: this.item.member_id,
      };
      this.$updateName(data);
      const conversationID = `C2C${this.item.member_id}`;
      localStorage.setItem("conversationID", conversationID);
      this.$router.push("/chat");
    },
    MouseOver() {
      this.isMask = true;
      this.$refs.video.play();
    },
    MouseOut() {
      this.$refs.video.pause();
      this.isMask = false;
    },
    play() {
      if (this.isMask) {
        console.log(this.$refs.video);

        this.$refs.video.play();
      }
    },
    jumpRY(item) {
      this.$router.push({
        path: "/notesDetials",
        query: { item: JSON.stringify(item) },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.staff {
  position: relative;
  width: 100%;
  height: 280px;
  // background: #ffffff;
  background: #fff;
  border-radius: 18px;
  padding: 26px 19px;
  .staff_info {
    .staff_img {
      width: 150px;
      height: 200px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      object-fit: cover;
    }
    .staff_info_right {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 170px;
      .staff_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        div {
          font-size: 20px;
          color: #f43a47;
        }
      }
      .staff_tag {
        .staff_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .staff_text {
        width: 100%;
        font-size: 12px;
        color: #000;

        .staff_text_label {
          color: #666666;
        }
        .staff_text_values {
          display: flex;
          flex-wrap: wrap; /* 如果需要换行，可以启用换行 */
          gap: 8px; /* 给 span 之间的间距 */
        }
      }
    }
  }
  .staff_c {
    margin-top: 15px;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 25px;
    span {
      color: #666666;
    }
    img {
      width: 17px;
      height: 15px;
      margin-right: 10px;
    }
  }
}
.classBorder {
  border: 1px solid #eae8e8;
}

// 视频
.staff_video {
  margin-right: 10px;

  position: relative;
}
.dataImagesList {
  width: 230px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  .dataImagesList_item_img {
    margin: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
}

.positionDetails_btn {
  position: absolute;
  right: 10px;
  z-index: 999;
  top: 20px;

  margin-right: 15px;

  border-radius: 14px;

  font-size: 15px;
  color: #f43a47;
}
.ellipsis-container {
  width: 180px;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  word-break: break-all;
}
.staff_text_label1 {
  width: 105px;
}
</style>

