<template>
  <div id="app">
    <!-- <router-view /> -->
    <transition name="page-fade">
      <router-view></router-view>
    </transition>
    <div class="rightOption">
      <div class="rightOption_item" @click="top">
        <img src="@/assets/image/utils/one.png" alt="">
      </div>
      <!-- <div class="rightOption_item" @click="dialogVisible=true">
        <img src="@/assets/image/utils/tow.png" alt="">
      </div> -->
      <div class="rightOption_item" @click="dialogVisible=true">
        <img src="@/assets/image/utils/three.png" alt="">
      </div>
      <div class="rightOption_item" @click="dialogVisible1=true">
        <img src="@/assets/image/utils/four.png" alt="">
      </div>
    </div>
    <!-- 选择城市 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="40%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <!-- <i class="el-icon-refresh"></i> -->
          客服
        </div>
      </div>
      <div class="flex_aling_justify_center">
        <el-image style="width: 152px; height: 152px;border-radius:10px" :src="versionInfo.kefu" :preview-src-list="[versionInfo.kefu]" />
      </div>
      <!-- <SelectCity @selectCityback="selectCityback"></SelectCity> -->
    </el-dialog>
    <!-- 表单投诉 -->

    <el-dialog title="" :visible.sync="dialogVisible1" width="40%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <!-- <i class="el-icon-refresh"></i> -->
          投诉与建议
        </div>
      </div>
      <div>
        <el-row :gutter="20">
          <el-form label-position="top" class="demo-form-inline" size="mini" ref="ruleForm" :rules="rules" :model="form" @close="close">
            <el-col :span="15">
              <el-form-item label="反馈主体" prop="type">
                <el-select style="width:100%" v-model="form.type" placeholder="请选择">
                  <el-option label="个人" :value="1">
                  </el-option>
                  <el-option label="企业" :value="2">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="15">
              <el-form-item label="联系电话" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="15">
              <el-form-item label="	联系人" prop="name">
                <el-input v-model="form.name" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="15" prop="">
              <el-form-item label="图片">
                <SingleImage :limit="3" v-model="form.photo"></SingleImage>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="内容" prop="content">
                <el-input type="textarea" :rows="4" v-model="form.content" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="recruit_btn" @click="save">
          提交
        </div>
      </div>
      <!-- <SelectCity @selectCityback="selectCityback"></SelectCity> -->
    </el-dialog>
  </div>
</template>
<script>
import SingleImage from "@/components/SingleImage.vue";
import { city_list, version, add_word } from "@/utils/network";

export default {
  components: {
    SingleImage,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      form: {
        type: 1,
        phone: "",
        name: "",
        content: "",
        photo: [],
      },
      versionInfo: {},
      rules: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        content: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  async created() {
    this.getCityData();

    const res = await version();
    this.versionInfo = res.data;
    console.log(this.versionInfo, "versionInfo");
  },
  mounted() {
    // // 禁用右键菜单
    // document.addEventListener("contextmenu", this.disableContextMenu, false);
    // // 禁用 F12 和其他开发者工具快捷键
    // window.addEventListener("keydown", this.disableDevToolsShortcuts, true);
  },
  methods: {
    close() {
      this.dialogVisible1 = false;
      this.form = this.$options.data().form;
    },
    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.form,
            member_id: JSON.parse(localStorage.getItem("member_id")),
          };

          const res = await add_word(data);
          if (res.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.dialogVisible1 = false;
            this.form = this.$options.data().form;
          }
        } else {
        }
      });
    },
    disableContextMenu(event) {
      event.preventDefault();
    },
    disableDevToolsShortcuts(event) {
      if (
        event.key === "F12" ||
        (event.ctrlKey &&
          event.shiftKey &&
          (event.key === "I" || event.key === "C")) ||
        (event.ctrlKey && event.key === "u")
      ) {
        event.preventDefault();
      }
    },
    async getCityData() {
      const res = await city_list();
      let cityList = [];
      let letterlist = [...Array(26)].map((v, i) =>
        String.fromCharCode(i + 65)
      );

      res.data.res.forEach((item, i) => {
        letterlist.forEach((value, index) => {
          if (value == item.pinyin) {
            cityList[index]
              ? cityList[index].push(item)
              : (cityList[index] = []);
          }
        });
      });
      this.$store.commit("SetisCityList", cityList);
      this.$store.commit("SetHotCityList", res.data.hot);
    },
    top() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>


<style lang="scss" scoped>
// .page-fade-enter-active,
// .page-fade-leave-active {
//   transition: opacity 0.2s ease-out;
// }

// .page-fade-enter,
// .page-fade-leave-to {
//   opacity: 0;
// }
.rightOption {
  cursor: pointer;
  top: 48%;
  right: 0;
  position: fixed;
  z-index: 99;
  width: 43px;
  height: 170px;
  background: #ffffff;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .rightOption_item {
    img {
      width: 20px;
      height: 20px;
    }
  }
}
::v-deep .el-dialog {
  overflow: hidden;

  border-radius: 14px;
}
::v-deep .el-dialog__header {
  padding: 0;
}
// 登录框
.loginTitle {
  position: relative;
  height: 50px;
  background: linear-gradient(180deg, #fdf3f4, #fdf3f4 50%, #f2f4f7);
  .loginTitle_toggle {
    margin-left: 15px;

    color: #333333;

    font-weight: bold;
    font-size: 14px;
    color: #f43a47;
  }
}

.recruit_btn {
  margin: 0 auto;
  margin-top: 20px;
  width: 288px;
  height: 50px;
}

::v-deep .el-input__inner {
  height: 45px !important;
  background: #f8f8f8 !important;
  border-radius: 8px;
  border: none !important;
}

::v-deep .el-upload {
  background: #f8f8f8 !important;
}
::v-deep .el-textarea__inner {
  background: #f8f8f8 !important;
  border-radius: 8px;
  border: none !important;
}
</style>
