<template>
  <div class="pageBt">
    <div class="Btinfo   ">
      <div class="left ">
        <div class="pageBt_title">
          联系我们
        </div>
        <div class="companyName">{{versionInfo.company_name}}</div>
        <div class="companyName_info">
          <img src="@/assets/image/telephone.png" alt="">
          服务热线/举报渠道：{{versionInfo.phone}}
        </div>
        <div class="companyName_info">
          <img class="images" src="@/assets/image/dizhi.png" alt="">
          公司地址：{{versionInfo.address}}
        </div>

      </div>

      <div class="right flex_aling_center">
        <div class="right_code">
          <div class="right_image flex_aling_justify_center">

            <!-- <el-image style="width: 122px; height: 122px;border-radius:10px" :src="versionInfo.qrcode" :preview-src-list="[versionInfo.qrcode]" /> -->
            <img class="xcx_qrcode" style="border-radius:10px" :src="versionInfo.xcx_qrcode" alt="" />
          </div>
          二维码
        </div>
        <div class="right_code" style="margin:0">
          <div class="right_image flex_aling_justify_center">
            <!-- <el-image style="width: 122px; height: 122px;border-radius:10px" :src="versionInfo.xcx_qrcode" :preview-src-list="[versionInfo.xcx_qrcode]" /> -->
            <img class="xcx_qrcode" style="border-radius:10px" :src="versionInfo.xcx_qrcode" alt="" />

          </div>
          小程序
        </div>

      </div>
    </div>
    <div class="copyright flex_aling_center">
      <span>Copyright © 2022 招聘人才网</span>
      <span>粤ICP备XXXX号-X</span>

      <div class="flex_aling_center">
        <div class="flex_aling_center"> <img src="@/assets/logo.png" alt=""> 电子营业执照 </div>
        <div class="flex_aling_center"> <img src="@/assets/logo.png" alt=""> 粤公网安备：110100100</div>
      </div>
      <span>人力资源服务许可证</span>
    </div>
  </div>
</template>

<script>
import { version } from "@/utils/network";
export default {
  data() {
    return {
      versionInfo: {},
    };
  },
  async created() {
    const res = await version();
    this.versionInfo = res.data;
  },
};
</script>

<style lang="scss" scoped>
.pageBt {
  height: 309px;
  background: #252f35;
  .Btinfo {
    padding: 30px 180px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // justify-content: center;
    align-items: center;
    .left {
      width: 50%;
      // margin-right: 102px;
      // padding-right: 36px;
      border-right: 1px solid #ffffff;
      .pageBt_title {
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 41px;
      }

      .companyName {
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
      }
      .companyName_info {
        margin-top: 15px;
        font-size: 14px;
        color: #ffffff;
        img,
        .images {
          width: 18px;
          height: 18px;
          margin-right: 15px;
        }
        .images {
          width: 15px;
        }
      }
    }
    .right {
      font-size: 16px;
      color: #ffffff;
      .right_code {
        margin-right: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .right_image {
          width: 122px;
          height: 122px;
          // background: #ffffff;
          border-radius: 10px;
          margin-bottom: 10px;
          img {
            object-fit: fill;
            width: 122px;
            height: 122px;
          }
        }
      }
    }
  }
  .copyright {
    padding: 30px 180px;
    width: 100%;
    height: 75px;
    background: #121d25;
    font-size: 12px;
    color: #ffffff;
    justify-content: space-between;
    img {
      width: 19px;
      height: 15px;
      margin: 0 10px;
    }
  }
}
.xcx_qrcode {
  width: 100px;
  height: 100px;
}
</style>