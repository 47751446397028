<template>
  <!-- 招聘人员 -->
  <div class="staff" :class="{'classBorder':isBorder}">
    <div class="staff_info flex">
      <img class="staff_img" :src="item.member.avatar" alt="">
      <div class="staff_info_right">
        <div class="staff_name flex_aling_center">
          <span> {{item.real_name}}</span>
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            视觉年龄：
          </div>
          {{item.note.begin_age}}- {{item.note.end_age}}岁
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label  staff_text_label1">
            擅长角色类型：
          </div>
          <!-- 风格类型 记得更改 -->
          <template v-if="item.note.can_person_attr.length>0">
            <div class="ellipsis-container">
              <span style="margin-right:3px" v-for="(value) in item.note.can_person_attr">{{ value }}</span>
            </div>
          </template>
          <!-- {{item.person_attr}} -->
        </div>
        <div class="staff_text  flex_aling_center" v-if="item.edu.school_name">
          {{item.edu.school_name}} | {{item.edu.major}}专业
        </div>
      </div>
    </div>
    <div class="staff_c  tow_ellipsis">
      <img src="@/assets/image/image/yuangong.png" alt="">
      <span>代表作：</span>
      <span class="work_name" v-for="(value,i) in item.work" :key="i">《{{value.work_name}}》</span>
    </div>

  </div>
</template>

<script >
export default {
  props: {
    isBorder: {
      type: Boolean,
      require: false,
      default: false,
    },
    item: {
      type: Object,
      require: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.staff {
  width: 100%;
  height: 252px;
  // background: #ffffff;
  background: #fff;
  border-radius: 18px;
  padding: 26px 19px;
  .staff_info {
    .staff_img {
      width: 103px;
      height: 129px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .staff_info_right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .staff_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        div {
          font-size: 20px;
          color: #f43a47;
        }
      }
      .staff_tag {
        .staff_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .staff_text {
        font-size: 16px;
        color: #000;
        .staff_text_label {
          color: #666666;
        }
        .staff_text_label1 {
          width: 125px;
        }
      }
    }
  }
  .staff_c {
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 25px;
    span {
      color: #666666;
    }
    img {
      width: 17px;
      height: 15px;
      margin-right: 10px;
    }
  }
}
.classBorder {
  border: 1px solid #eae8e8;
}
.ellipsis-container {
  width: 310px;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  word-break: break-all;
}
</style>

