<template>
  <div>
    <div ref="chart" class="chatClass"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    chatData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const xAxisData = this.chatData.map((item) => item.time);
      const seriesData = this.chatData.map((item) => item.count);
      // this.chartOptions.xAxis.data = xAxisData;
      // this.chartOptions.series[0].data = seriesData;

      this.chartInstance = echarts.init(this.$refs.chart);
      this.chartInstance.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisData,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "曝光量",
            type: "line",
            data: seriesData,
            lineStyle: {
              color: "#dc455a", // 设置线条颜色
            },
            itemStyle: {
              color: "#dc455a", // 设置数据点颜色
            },
          },
        ],
      });
    },
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
};
</script>

<style scoped>
/* 根据需要调整图表容器的样式 */
.chatClass {
  width: 100%;
  width: 700px;
  height: 400px;
}
</style>